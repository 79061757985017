import axios from "axios";
const TOKEN = "Bearer 5bb4cb5e7b93858d18c0e18f85fb1376";

import { setCustomers } from "./localbase";

export default {
  async getClienti() {
    const data = new FormData();
    data.append("offset", "0");

    const response = await axios.post("/search/customers", data, {
      headers: {
        Authorization: TOKEN,
      },
    });
    //qui salvo in indexeddb la collection Clienti nel db Clienti
    // con chiave id cliente e valore il cliente
    response.data.data.forEach((cliente) => {
      setCustomers(cliente);
    });

    //console.log(response);
    return response.data.data;
  },

  async getClienteDetail(id: string) {
    const data = new FormData();
    data.append("where[customers_id]", id.toString());

    const response = await axios.post("/search/customers", data, {
      headers: {
        Authorization: TOKEN,
      },
    });
    //console.log(response);
    return response.data.data[0];
  },
};
